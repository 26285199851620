<template>
    <Select
        :value="value"
        :get-data-vuex="getIsoCurrencies"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        class="white is-static"
        :clearable="false"
        field="label"
        prop="value"
        @input="$emit('input', $event)">
    </Select>
</template>

<script>
  import { IsoCurrencyEnum } from "@core/store/types/common/enums/isoCurrencyEnum";
  import { formatEmptyString } from "@core/filters";
  import Select from "@/components/Common/Select/Select.vue";
  import BaseSelect from "@/components/Common/Base/BaseSelect.vue";

  export default {
    name: "IsoCurrenciesSelect",
    extends: BaseSelect,
    components: { Select },
        
    methods: {
      getIsoCurrencies () {
        const items = Object.values(IsoCurrencyEnum).map(value => ({ value, label: value }));
        return {
          items,
          count: items.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>

</style>